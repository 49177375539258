@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body::-webkit-scrollbar {
  display: none;
}

html,
body {
  max-width: 100vw;
  overflow-x: clip;
}

body {
  background: black;
}

a {
  color: inherit;
  text-decoration: none;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@layer components {
  .theme-body1 {
    @apply font-inter
      text-lg leading-normal antialiased;
    font-family: "inter", sans-serif;
  }
  .theme-body2 {
    @apply font-inter text-base leading-normal antialiased;
    font-family: "inter", sans-serif;
  }
  .theme-h2 {
    @apply text-4xl font-semibold leading-normal;
    font-family: var(--font-eb-garamond);
  }
  .theme-h3 {
    @apply text-2xl font-semibold leading-normal;
    font-family: "inter", sans-serif;
  }
  .theme-h4 {
    @apply text-xl font-semibold leading-normal;
    font-family: var(--font-eb-garamond);
  }
  .theme-small-cta {
    @apply text-base font-semibold leading-normal tracking-[2.4px];
    font-family: var(--font-eb-garamond);
  }
  .theme-large-cta {
    @apply text-lg font-semibold leading-normal tracking-[2.7px];
    font-family: var(--font-eb-garamond);
  }
  .theme-small-text1 {
    @apply text-sm font-normal leading-normal antialiased;
    font-family: "inter", sans-serif;
  }
  .theme-small-text2 {
    @apply text-xs font-normal leading-normal antialiased;
    font-family: "inter", sans-serif;
  }
}

@keyframes scroll {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(calc(-100% - var(--marquee-gap)));
  }
}

.marquee--titanic {
  display: flex;
  overflow: hidden;
  --marquee-gap: 1rem;
  user-select: none;
  gap: var(--marquee-gap);
}

.marquee-content--titanic {
  display: flex;
  min-width: 100%;
  justify-content: space-around;
  flex-shrink: 0;
  gap: var(--marquee-gap);
  will-change: transform;
}

.scroll {
  @media only screen and (max-width: 600px) {
    animation: scroll 30s linear infinite;
  }
  animation: scroll 10s linear infinite;
}

.bg-bubbles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.bg-bubbles > li {
  position: absolute;
  width: 20px;
  height: 20px;
  background-image: radial-gradient(#6dd5ed40, #84b9bf30, #78a4bf65);
  list-style: none;
  animation: square 15s infinite;
  transition-timing-function: linear;
  border-radius: 50%;
}

@keyframes square {
  0% {
    opacity: 0.5;
    transform: translateY(0px) rotate(45deg);
  }
  25% {
    opacity: 0.75;
    filter: blur(4px);
    transform: translateY(-400px) rotate(90deg);
  }
  50% {
    opacity: 1;
    transform: translateY(-600px) rotate(135deg);
  }
  100% {
    opacity: 0;
    filter: blur(4x);
    transform: translateY(-1000px) rotate(180deg);
  }
}

.wrapper::after {
  width: 100%;
  height: 656;
  content:"";
  position:absolute;
}

.box {
  width: 300px;
  height: 65.6px;
  position:absolute;
}

.boxes {
  display: flex;
  flex-direction: column;
  position:relative;
}

.marquee {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  --marquee-gap: 1rem;
  user-select: none;
  gap: var(--marquee-gap);
}

.marquee-content {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: space-around;
  flex-shrink: 0;
  gap: var(--marquee-gap);
  will-change: transform;
}

.marquee-scroll {
  @media only screen and (max-width: 600px) {
    animation: scroll 60s linear infinite;
  }
  animation: scroll 60s linear infinite;
}

.carousel .slide{
  background: transparent !important;
}
